/** @jsx jsx */
import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../components/seo"
import { SEOProduct } from "../components/seoproduct"

import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import IconTick from "../images/icons/check.svg"
import IconArrowRight from "../images/icons/arrow-right.svg"

import { Link, navigate } from "gatsby"
import Page from "../layouts/page"

const ColocationProductList = [
  {
    name: 'Colocation',
    learn_more_url: '/articles/colocation',
    points: [
      'Central London based datacentre (E1)',
      '5 minute walk from Liverpool Street station',
      'Flexible arrangements to suit your needs',
      'Excellent connectivity available on-site',
      'Affordable remote hands available',
      'Site access on request',
      '1 Gbit/s bandwidth',
      '10 Gbit/s peering and intra-customer network',
      'BGP peering available',
      'Enquiries only'
    ],
    description: 'Central London based datacentre (E1)',
    cta: 'Enquire',
    sku: 'COLO-LON1',
    cost: null,
    period: null
  }
]

const styles = css`
.article-cta {
  display: flex;
  background: #fff;
  border: 1px solid #232323;
  border-radius: 0;
  margin-top: 12px;

  .heading, p {
    margin: 0;
    color: #000;
  }

  .info {
    display: flex;
    width: 75%;
    padding: 24px;
    align-items: center;

    font-weight: lighter;

    .content .heading {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
  .cta {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: center;

    a {
      color: #fff;
    }
    
    .button {
      display: inline-block;
      padding: 8px 16px 8px 16px;
      font-weight: lighter;

      background: #2e2e2e;
      border: 1px solid #2e2e2e;

      .arrow-right {
        display: inline-block;

        background-image: url(${IconArrowRight});
        background-size: 16px 16px;
        filter: invert(1);

        height: 16px;
        width: 16px;

        vertical-align: middle;
      }
    }
  }
}

@media (max-width: 700px) {
  .article-cta {
    display: block;

    .info {
      width: auto;
      padding-bottom: 0;
    }
    .cta {
      display: block;
      width: auto;
      text-align: center;
      padding: 12px;
    }
  }
}
`

const productList = css`
max-width: 70%;
margin: 0 auto;

.product {
  display: flex;

  color: #000;
  background: #fff;

  border: 1px solid #232323;
  margin-top: 12px;

  .head {
    display: flex;
    width: 25%;

    text-align: center;
    border-right: 1px solid #ddd;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0px;
    }
    .subtitle {
      font-weight: 300;
    }
  }
  .body {
    width: 60%;

    font-size: 14px;
    font-weigt: lighter;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-weight: lighter;
        padding: 8px 10px 8px 10px;
        
        &:not(:last-child) {
          border-bottom: 1px dashed #ddd;
        }

        span.tick {
          display: inline-block;

          background-image: url(${IconTick});
          background-size: 16px 16px;
          filter: invert(0);

          height: 16px;
          width: 16px;

          margin-right: 2px;

          margin-top: -2px;
          vertical-align: middle;
        }
      }
    }
  }
  .cta {
    width: 15%;

    display: flex;
    padding: 16px;
    text-align: center;
    border-left: 1px solid #ddd;
    align-items: center;
    justify-content: center;

    a, a:hover {
      color: #fff;
    }

    button {
      all: unset;
      color: #fff;
      cursor: pointer;
      font-weight: lighter;
    }

    .button {
      display: inline-block;
      padding: 8px 16px 8px 16px;

      background: #2e2e2e;
      border: 1px solid #2e2e2e;

      .arrow-right {
        display: inline-block;

        background-image: url(${IconArrowRight});
        background-size: 16px 16px;
        filter: invert(1);

        height: 16px;
        width: 16px;

        vertical-align: middle;
      }
    }

  }
}

@media (max-width: 870px) {
  max-width: 95%;
  margin: 0 auto;

  .product {
    display: block;
    .head {
      width: auto;

      border: none;
      border-bottom: 1px solid #ddd;

      .info {
        padding: 18px;
      }
    }
    .body {
      width: auto;

      border-bottom: 1px solid #ddd;
    }
    .cta {
      width: auto;
      border: none;
    }
  }
}
`

// markup
const ColocationPage = () => {

  const dispatch = useDispatch()

  const doOrder = (item) => {
    if(!item.cost) {
      return navigate('/contact-us')
    }
    
    addSKU(item.sku)
  }

  const addSKU = useCallback(
    (sku) => {
      if(!sku) {
        return navigate('/contact-us')
      }

      dispatch({
        type: 'ADD_SKU',
        sku,
      })

      navigate('/order')
    }, [dispatch])

  return <Page>
    <div css={styles}>
      <div className="contain page-body">
        <h1>Server Colocation</h1>

        <p>We are able to offer you access to colocate your server equipment in Central London.</p>

        <div css={productList}>
          {ColocationProductList.map((item) => 
          <div className="product" key={item.name}>

            <div className="head">
              <div className="info">
                <div className="title">{item.name}</div>
                <div className="about">
                  <Link to={item.learn_more_url}>Learn more..</Link>
                </div>
              </div>
            </div>
            <div className="body">
              <ul>
                {item.points.map((point) =>
                <li key={point}>
                  <span className="tick"></span> {point}
                </li>
                )}
              </ul>
            </div>
            <div className="cta">
              <button onClick={() => doOrder(item)}>
                <div className="button">{item.cta} <span className="arrow-right"></span></div>
              </button>
            </div>
          </div>
          )}

          <div className="article-cta">
            <div className="info">
              <div className="content">
                <div className="heading">Looking for something else?</div>
                <p>If you're after something in particular we don't list here, please get in touch and we'd be happy to make you a custom quote.</p>
              </div>
            </div>
            <div className="cta">
              <Link to="/contact-us">
                <div className="button">Enquire <span className="arrow-right"></span></div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <>
    <SEO
      title="Server Colocation"
      description="Our colocation services provide a reliable and secure environment in central London for your server hardware."
      url="https://infernocomms.com/colocation"
    />

    {ColocationProductList.map((item) =>
      <SEOProduct
        name={item.name}
        description={item.description}
        sku={item.sku}
        path="/colocation"
        price={item.cost}
        key={item.sku}
      />
    )}
  </>
)

export default ColocationPage
