import * as React from "react"
//import { jsx } from "@emotion/react"

import { useSiteMetadata } from "../hooks/use-site-metadata"

const SEOProduct = ({
  name = null,
  description = null,
  sku = null,
  path = null,
  price = null,
  images = []
}) => {
  const { siteUrl } = useSiteMetadata()

  const url = siteUrl + path

  const richResult = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": name,
    "image": images,
    "description": description,
    "sku": sku,
    "brand": {
      "@type": "Organization",
      "name": "Inferno Communications",
      "legalName": "Inferno Communications Ltd",
      "url": "https://infernocomms.com/"
    },
    "offers": {
      "@type": "Offer",
      "url": url,
      "priceCurrency": "GBP",
      "price": price / 100,
      "availability": "https://schema.org/OnlineOnly"
    }
  }

  return <script type="application/ld+json">
    {JSON.stringify(richResult)}
  </script>
}

export { SEOProduct }